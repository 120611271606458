package ui.create

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import data.LocalPlaces
import data.PlacesRepository
import data.place
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.search
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaTextField
import util.Place

@Composable
fun LocationSearch(
    onSelectedPlace: (Place) -> Unit
) {

    val places = LocalPlaces.current
    var locationName by remember { mutableStateOf("") }

    var autoCompleteJob: Job? = null
    val locationSuggestions = remember { mutableStateListOf<PlacesRepository.PlacePrediction>() }

    var loading by remember { mutableStateOf(false) }

    val coroutineScope = rememberCoroutineScope(
        getContext = { Dispatchers.Unconfined }
    )

    Box(
        modifier = Modifier
            .fillMaxSize(),
    ) {
                Column(Modifier.fillMaxHeight()) {
                    Spacer(Modifier.height(48.dp))

                    Text(
                        modifier = Modifier.align(Alignment.CenterHorizontally),
                        text = "Which city are you based in?",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold,
                    )

                    Text(
                        modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 4.dp),
                        text = "This helps locals find you",
                        fontSize = 14.sp
                    )

                    Spacer(Modifier.height(24.dp))

                    Column {
                        LocaTextField(
                            value = locationName,
                            onValueChange = {
                                locationName = it
                                autoCompleteJob?.cancel()
                                autoCompleteJob = coroutineScope.launch {
                                    val forWord = it
                                    if (it.isNotEmpty()) {
                                        locationSuggestions.clear()
                                        loading = true
                                        delay(1500)
                                        if (locationName == forWord) {
                                            val results = places.autocomplete(locationName).features
                                            if (locationName == forWord) {
                                                locationSuggestions.addAll(results)
                                                loading = false
                                            }
                                        }
                                    }
                                }
                            },
                            label = "City",
                            leadingIcon = {
                                Icon(
                                    imageVector = vectorResource(Res.drawable.search),
                                    contentDescription = "Search"
                                )
                            },
                            trailingIcon = {
                                if (loading) {
                                    CircularProgressIndicator(
                                        modifier = Modifier.size(24.dp)
                                    )
                                }
                            },
                            keyboardOptions = KeyboardOptions.Default.copy(
                                capitalization = KeyboardCapitalization.Words
                            )
                        )

                        Box(
                            modifier = Modifier
                                .clip(RoundedCornerShape(24.dp))
                                .background(MaterialTheme.colorScheme.surfaceContainer)
                                .weight(1f, true)
                                .fillMaxWidth()
                        ) {

                            LazyColumn {
                                items(locationSuggestions) {
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .clip(RoundedCornerShape(24.dp))
                                            .clickable(enabled = !loading) {
                                                coroutineScope.launch {
                                                    loading = true
                                                    locationSuggestions.clear()
                                                    onSelectedPlace(it.place)
                                                }
                                            }
                                            .padding(24.dp),
                                    ) {
                                        Text(
                                            text = it.properties.name,
                                            fontWeight = FontWeight.Medium,
                                            fontSize = 16.sp,
                                        )

                                        Spacer(Modifier.height(8.dp))

                                        val state = it.properties.state?.let { "$it, " } ?: ""
                                        Text(
                                            text = "$state${it.properties.country}"
                                        )
                                    }
                                }

                                item {
                                    Spacer(Modifier.height(48.dp))
                                }
                            }

                            Box(
                                modifier = Modifier
                                    .padding(12.dp)
                                    .clip(CircleShape)
//                                    .background(MaterialTheme.colorScheme.onBackground)
                                    .padding(horizontal = 12.dp)
                                    .align(Alignment.BottomEnd)
                            ) {
                                Text("© OpenStreetMap")
                            }
                        }

                        Spacer(Modifier.height(48.dp))

                    }
                }
    }
}