package data

import Ads
import AudioPlayer
import LocaNavigator
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.mutableStateOf
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.functions.Functions
import io.github.jan.supabase.gotrue.Auth
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.postgrest.PropertyConversionMethod
import io.github.jan.supabase.serializer.KotlinXSerializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonNamingStrategy
import model.Point
import util.Location

private val http = LocaHttp()
val LocalSpotify = compositionLocalOf { SpotifyRepository(http) }
val LocalSpotifyPlayer = compositionLocalOf { mutableStateOf<SpotifyPlayer?>(null) }
val LocalSpotifyPlayerState = compositionLocalOf { mutableStateOf<SpotifyPlayerState?>(null) }
val LocalPlaces = compositionLocalOf { PlacesRepository(http) }
val LocalAudioPlayer = compositionLocalOf { AudioPlayer() }
val LocalLocation = compositionLocalOf { mutableStateOf<Location?>(Location("Brisbane", Point(-27.470125, 153.021072))) }
val LocalAds = compositionLocalOf { mutableStateOf<Ads?>(null) }
val LocaNavigator = compositionLocalOf { mutableStateOf<LocaNavigator?>(null) }
val LocalAnalytics = compositionLocalOf { Analytics() }
val LocalSupabase = compositionLocalOf {
    createSupabaseClient(
        supabaseUrl = "https://wqclphjceunnlzeflusz.supabase.co",
        supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndxY2xwaGpjZXVubmx6ZWZsdXN6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM2ODg3MTIsImV4cCI6MjAzOTI2NDcxMn0.k78AAzorndUCzPfuaNCo04W6wMXiFjKgGnL7O5_xunA"
    ) {

        defaultSerializer = KotlinXSerializer(Json {
            prettyPrint = true
            isLenient = true
            ignoreUnknownKeys = true
            namingStrategy = JsonNamingStrategy.SnakeCase
        })

        install(Auth) {
            autoLoadFromStorage = true
            autoSaveToStorage = true
        }

        install(Postgrest) {
            propertyConversionMethod = PropertyConversionMethod.CAMEL_CASE_TO_SNAKE_CASE
        }

        install(Functions)

//        install(ComposeAuth) {
//            googleNativeLogin(serverClientId = "google-client-id")
//            appleNativeLogin()
//        }
    }
}

// Location("Brisbane", GeoPoint(-27.470125, 153.021072))