package ui.create

//import com.fleeksoft.ksoup.Ksoup
//import com.fleeksoft.ksoup.nodes.Document
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import com.fleeksoft.ksoup.Ksoup
import com.fleeksoft.ksoup.nodes.Document
import data.LocalSupabase
import io.github.jan.supabase.functions.functions
import io.ktor.client.statement.bodyAsText
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.component.LocaTextField

@Preview
@Composable
fun NewLinkPreview() {
//    NewLink {  }
}

@Serializable
data class UrlRequest(val url: String)

@Serializable
data class NameRequest(val name: String)

@Composable
fun NewLink(
    onFinish: (url: String, title: String, picture: String) -> Unit,
) {

    var link by remember { mutableStateOf("") }
    var title by remember { mutableStateOf("") }
    var picture by remember { mutableStateOf("") }
    var document by remember { mutableStateOf<Document?>(null) }


    val supabase = LocalSupabase.current
    val coroutineScope = CoroutineScope(Dispatchers.Unconfined)
    var job: Job? by remember { mutableStateOf(null) }

    LaunchedEffect(link) {
        job?.cancel()
        if (link.isNotEmpty()) {
            val toCheck = link
            job = coroutineScope.launch {
                delay(1000)
                if (toCheck == link) {
                    val response = supabase.functions.invoke(
                        function = "fetch",
                        body = UrlRequest(link)
                    )
                    val doc: Document = Ksoup.parse(response.bodyAsText())
                    val metadata = doc.head().getElementsByTag("meta")
                    metadata.forEach {
                        println(it.toString())
                    }
                    title = doc.title()
                    picture = metadata
                        .filter { it.attribute("property")?.value == "og:image" }
                        .map { it.attribute("content")?.value }
                        .firstOrNull()
                        ?: ""
                }
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 24.dp)
    ) {

        Spacer(Modifier.height(24.dp))

        LocaTextField(
            modifier = Modifier.fillMaxWidth(),
            value = link,
            onValueChange = { link = it },
            label = "Link"
        )

        Spacer(Modifier.height(24.dp))

        Text(title)

        Spacer(Modifier.height(24.dp))

        AsyncImage(
            modifier = Modifier
                .size(72.dp),
            model = picture,
            contentDescription = null
        )

        Spacer(Modifier.height(24.dp))

        Button(onClick = {
            onFinish(
                link,
                title,
                picture,
            )
        }) {
            Text("Confirm")
        }

    }
}