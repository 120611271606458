package ui

import AuthProvider
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import data.LocalSupabase
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.FirebaseAnalyticsEvents
import dev.gitlive.firebase.analytics.analytics
import io.github.jan.supabase.gotrue.auth
import io.github.jan.supabase.gotrue.providers.Google
import io.github.jan.supabase.gotrue.providers.builtin.Email
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.eye
import loca.composeapp.generated.resources.eye_off
import loca.composeapp.generated.resources.google
import loca.composeapp.generated.resources.logo
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaTextField

enum class SigningInState {
    LOGIN,
    REGISTER
}

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun LoginScreen(
    authProvider: AuthProvider,
    signingInState: SigningInState = SigningInState.REGISTER,
    onLoggedIn: () -> Unit,
) {
    val supabase = LocalSupabase.current

    var emailValue by remember { mutableStateOf("") }
    var passwordValue by rememberSaveable { mutableStateOf("") }
    var passwordVisible by remember { mutableStateOf(false) }
    var loading by remember { mutableStateOf(false) }
    var state by remember { mutableStateOf(signingInState) }
    var failed by remember { mutableStateOf(false) }

    val coroutineScope = rememberCoroutineScope(
        getContext = { Dispatchers.Unconfined }
    )

    val analytics = Firebase.analytics
    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester.createRefs()

    fun tryLogin() {
        coroutineScope.launch {
            loading = true
            try {
                val result = when (state) {
                    SigningInState.REGISTER -> {
                        supabase.auth.signUpWith(Email) {
                            email = emailValue
                            password = passwordValue
                        }
//                        auth.createUserWithEmailAndPassword(
//    email,
//    password
//                        )
                    }

                    SigningInState.LOGIN -> {
//                        auth.signInWithEmailAndPassword(email, password)
                        supabase.auth.signInWith(Email) {
                            email = emailValue
                            password= passwordValue
                        }
                    }
                }
//
//                if (result.user != null) {
//                    withContext(Dispatchers.Main) {
//                        onLoggedIn()
//                    }
//                }

                loading = false
            } catch (e: Exception) {
                failed = true
                loading = false
            }
        }
    }

    LaunchedEffect(true) {
        analytics.logEvent(
            FirebaseAnalyticsEvents.SCREEN_VIEW, parameters = mapOf(
            "screen_name" to "LoginScreen"
        ))
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
    ) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .align(Alignment.Center)
            .padding(horizontal = 24.dp)
            .padding(bottom = 48.dp)
    ) {

        Icon(
            modifier = Modifier
                .align(Alignment.CenterHorizontally)
                .size(128.dp),
            imageVector = vectorResource(Res.drawable.logo),
            contentDescription = "Loca"
        )

        Spacer(Modifier.height(48.dp))

        LocaTextField(
            modifier = Modifier
                .fillMaxWidth()
                .onPreviewKeyEvent {
                                   false
//                    if (it.key == Key.Tab && it.nativeKeyEvent == KeyEventType.KeyDown){
//                        focusManager.moveFocus(FocusDirection.Down)
//                        true
//                    } else {
//                        false
//                    }
                },
            value = emailValue,
            onValueChange = {
                failed = false
                emailValue = it
            },
            label = "Email",
            isError = failed,
            keyboardOptions = KeyboardOptions(imeAction = ImeAction.Next)
        )

        Spacer(Modifier.height(0.dp))

        LocaTextField(
            modifier = Modifier.fillMaxWidth(),
            value = passwordValue,
            onValueChange = {
                failed = false
                passwordValue = it
            },
            label = "Password",
            visualTransformation = if (passwordVisible) VisualTransformation.None else PasswordVisualTransformation(),
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Password,
                imeAction = ImeAction.Go,
            ),
            keyboardActions = KeyboardActions(
                onGo = { tryLogin() }
            ),
            trailingIcon = {
                Box(modifier = Modifier.padding(4.dp)) {
                    IconButton(onClick = { passwordVisible = !passwordVisible }) {
                        Box(Modifier.size(24.dp)) {
                            Icon(
                                imageVector = if (passwordVisible) {
                                    vectorResource(Res.drawable.eye)
                                } else {
                                    vectorResource(Res.drawable.eye_off)
                                },
                                contentDescription = if (passwordVisible) "Hide Password" else "Show Password"
                            )
                        }
                    }
                }
            },
            isError = failed
        )

        Spacer(Modifier.height(24.dp))

        Button(
            modifier = Modifier.fillMaxWidth(),
            onClick = {
                tryLogin()
            },
            enabled = !loading && emailValue.isNotEmpty() && passwordValue.isNotEmpty(),
            shape = CircleShape
        ) {
            val text = when (state) {
                SigningInState.LOGIN -> "Sign in"
                SigningInState.REGISTER -> "Create account"
            }
            Text(
                modifier = Modifier.padding(12.dp),
                text = text,
                fontSize = 16.sp,
            )
        }

        Spacer(Modifier.height(24.dp))

        Text(
            modifier = Modifier.align(Alignment.CenterHorizontally),
            text = "or",
            fontWeight = FontWeight.SemiBold
        )

        Spacer(Modifier.height(24.dp))

        OutlinedButton(
            modifier = Modifier.align(Alignment.CenterHorizontally).fillMaxWidth(),
            onClick = {
//                authProvider.signInWithGoogle()
                coroutineScope.launch {
                    supabase.auth.signInWith(Google, "https://wqclphjceunnlzeflusz.supabase.co/auth/v1/callback")
                }
            },
            shape = CircleShape,
        ) {
            Icon(
                imageVector = vectorResource(Res.drawable.google),
                contentDescription = "Google"
            )
            Text(
                modifier = Modifier.padding(16.dp),
                text ="Continue with Google"
            )
        }

        Spacer(Modifier.height(24.dp))

        Row(
            modifier = Modifier.align(Alignment.CenterHorizontally)
        ) {
            when (state) {
                SigningInState.REGISTER -> {
                    Text(
                        modifier = Modifier.align(Alignment.CenterVertically),
                        text = "Already have an account?",
                        fontWeight = FontWeight.Medium
                    )
                    TextButton(onClick = { state = SigningInState.LOGIN }) {
                        Text("Sign in")
                    }
                }

                SigningInState.LOGIN -> {
                    Text(
                        modifier = Modifier.align(Alignment.CenterVertically),
                        text = "Don't have an account?",
                        fontWeight = FontWeight.Medium
                    )
                    TextButton(onClick = { state = SigningInState.REGISTER }) {
                        Text("Sign up")
                    }
                }
            }
        }
    }
    }
}