package ui.create

import Link
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.unit.dp
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.close
import loca.composeapp.generated.resources.dragger
import loca.composeapp.generated.resources.edit
import org.jetbrains.compose.resources.vectorResource
import ui.component.DraggableLazyList

@Composable
fun LinksEditor(
    links: List<Link>,
    onNewLink: () -> Unit,
    onConfirm: () -> Unit,
    onEditLink: (link: Link) -> Unit,
) {


    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 24.dp)
    ) {

        Spacer(Modifier.height(24.dp))

        OutlinedButton(
            modifier = Modifier.fillMaxWidth(),
            onClick = { onNewLink() },
            shape = RoundedCornerShape(24.dp),
        ) {
            Icon(
                modifier = Modifier.rotate(45f).padding(8.dp)
                    .align(Alignment.CenterVertically),
                imageVector = vectorResource(Res.drawable.close),
                contentDescription = "Add",
            )
            Text(
                modifier = Modifier.padding(vertical = 24.dp),
                text = "Add a link"
            )
        }

        DraggableLazyList(
            modifier = Modifier.weight(1f, true),
            items = links.toList().map { link ->
                {
                    LinkListItem(link) {
                        onEditLink(link)
                    }
                }
            },
            onSwap = { i, x ->
//                (links[i] to links[x]).also {
//                    links[x] = it.first
//                    links[i] = it.second
//                }
            }
        )

    }
}

@Composable
fun LinkListItem(
    link: Link,
    onEditClick: () -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth()
    ) {
        Icon(
            modifier = Modifier.align(Alignment.CenterVertically),
            imageVector = vectorResource(Res.drawable.dragger),
            contentDescription = "Drag"
        )

        Spacer(Modifier.width(12.dp))

        Column(
            modifier = Modifier
                .weight(1f)
                .clip(RoundedCornerShape(24.dp))
                .background(MaterialTheme.colorScheme.surfaceContainer)
                .padding(24.dp)
        ) {
            Text(
                text = link.title,
                color = MaterialTheme.colorScheme.onSurface
            )
            Text(
                text = link.url,
                color = MaterialTheme.colorScheme.onSurface
            )
        }

        IconButton(
            modifier = Modifier
                .padding(horizontal = 4.dp)
                .align(Alignment.CenterVertically),
            onClick = { onEditClick() },
        ) {
            Icon(
                imageVector = vectorResource(Res.drawable.edit),
                contentDescription = "Edit"
            )
        }
    }
}
