
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.w3c.dom.Audio

actual class AudioPlayer {
    private var audio: Audio? = null
    private val coroutineScope = CoroutineScope(Dispatchers.Unconfined)
    private var job: Job? = null
    private var dummyTime: Double = 0.0
    actual fun playAudio(url: String, muted: Boolean, onProgressUpdate: (Double) -> Unit) {
        job?.cancel()
        job = null
        audio?.pause()
        audio = Audio(url)
        audio?.volume = 0.5
        audio?.autoplay = true
        audio?.muted = muted
        audio?.play()?.catch {
            job = coroutineScope.launch {
                dummyTime = 0.0
                val duration = 30.0
                while (dummyTime < duration && audio?.currentTime == 0.0) {
                    delay(330)
                    dummyTime += 0.33
                    onProgressUpdate(dummyTime / duration)
                }

                if (audio?.currentTime == 0.0) {
                    onProgressUpdate(1.0)
                }
            }
        }

        audio?.ontimeupdate = { update ->
            val currentTime = audio?.currentTime
            val duration = audio?.duration
            if (currentTime != null && duration != null) {
                onProgressUpdate(currentTime / duration)
            }
        }
    }

    actual fun mute(muted: Boolean) {
        audio?.muted = muted
        if (!muted && dummyTime > 0 && audio?.currentTime == 0.0) {
            audio?.currentTime = dummyTime
            dummyTime = 0.0
            audio?.play()
        }
    }

    actual fun stopAudio() {
        audio?.pause()
        audio = null
    }
}