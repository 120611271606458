package data

import io.ktor.client.call.body
import io.ktor.client.request.parameter
import io.ktor.client.statement.bodyAsText
import io.ktor.http.HttpMethod
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import model.Point
import util.Place

private const val API_KEY = "AIzaSyAOGz1wBV9JsIpIKMM52hiEE4BrqtWCvq0"

class PlacesRepository(private val client: LocaHttp) {

    @Serializable
    data class PlacePrediction(
        val properties: PlaceProperties,
        val geometry: Geometry,
    )

    @Serializable
    data class PlaceAutoCompleteResponse(
        val features: List<PlacePrediction>
    )

    @Serializable
    data class LatLngLiteral(
        val coordinates: List<Double>
    )

    @Serializable
    data class Geometry(
        val coordinates: List<Double>
    )

    @Serializable
    data class PlaceProperties(
        val name: String,
        val state: String? = null,
        val country: String,
        @SerialName("osm_id") val osmId: String,
        val osmKey: String,
        val osmValue: String,
    )

//    @Serializable
//    data class PlaceDetailsResponse(
//        val result: Place
//    )

    @Serializable
    data class CurrentLocationResponse(
        val latitude: Double,
        val longitude: Double,
    )

    suspend fun autocomplete(value: String) =
        client.request("https://photon.komoot.io/api/") {
            method = HttpMethod.Get
            parameter("q", value)
//            parameter("layer", "city")
            parameter("osm_tag", "place")
        }.apply {
            println(this.bodyAsText())
        }.body<PlaceAutoCompleteResponse>()

//    suspend fun placeDetails(id: String) =
//        client.request("https://maps.googleapis.com/maps/api/place/details/json") {
//            method = HttpMethod.Get
//            parameter("place_id", id)
//            parameter("fields", "name,geometry")
//            parameter("key", API_KEY)
//        }.body<PlaceDetailsResponse>().result

    suspend fun currentLocation(): Point =
        client.request("https://ipwho.is/") {
            method = HttpMethod.Get
        }.body<CurrentLocationResponse>().let { response ->
            Point(response.latitude, response.longitude)
        }

}

val PlacesRepository.PlacePrediction.place get() = this.let { prediction ->
    val prop = prediction.properties
    val coordinates = prediction.geometry.coordinates
    Place(
        name = prop.name,
        osmId = prop.osmId,
        osmKey = prop.osmKey,
        osmValue = prop.osmValue,
        country = prop.country,
        state = prop.state,
        point = Point(coordinates[0], coordinates[1])
    )
}