package data

import Artist
import Link
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.gotrue.auth
import io.github.jan.supabase.postgrest.from
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import util.Place

class ArtistRepository(private val supabase: SupabaseClient) {

    suspend fun create(handle: String) =
        supabase
            .from("artists")
            .insert(buildJsonObject { put("handle", handle) })
            { select() }
            .decodeSingleOrNull<Artist>()

    suspend fun update(artist: Artist) =
        supabase.from("artists").update(
            artist.copy(
                editors = null,
                links = null,
                place = null,
            )
        ) {
            filter {
                eq("id", artist.id ?: 0)
            }
            select()
        }.also {
            println("artist: $artist")
        }.decodeSingleOrNull<Artist>()

    suspend fun get(handle: String) =
        supabase.from("artists").select(
            columns = Columns.raw("*, places(*), links(*)")
        ) {
            filter {
                eq("handle", handle)
            }
        }
            .apply { println(data) }
            .decodeSingleOrNull<Artist>()

    suspend fun get(id: Int) =
        supabase.from("artists").select(Columns.raw(
            "id, name"
        )) {
            filter {
                eq("id", id)
            }
        }.decodeSingleOrNull<Artist>()

    suspend fun accountList() =
        supabase.auth.currentUserOrNull()?.let { user ->
            supabase
                .from("artists")
                .select(Columns.raw("*, editors(*), places(*), links(*)")) {
                    filter {
                        eq("created_by", user.id)
                    }
                }.also { println(it.data) }
                .decodeList<Artist>()
        }

    suspend fun addLink(link: Link) =
        supabase
            .from("links")
            .insert(link)
            { select() }
            .decodeSingleOrNull<Link>()

    suspend fun addOrGetPlace(place: Place) =
        supabase
            .from("places")
            .select {
                filter {
                    eq("osm_id", place.osmId)
                }
            }
            .decodeSingleOrNull<Place>()
            ?: supabase
                .from("places")
                .insert(place)
                { select() }
                .decodeSingleOrNull<Place>()
}

val SupabaseClient.artists get() = ArtistRepository(this)
