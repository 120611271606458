
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredWidthIn
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import cafe.adriel.voyager.core.annotation.ExperimentalVoyagerApi
import data.LocalAds
import data.LocalAudioPlayer
import data.LocalLocation
import data.LocalPlaces
import data.LocalSpotifyPlayer
import data.LocalSpotifyPlayerState
import data.LocalSupabase
import data.LocationServices
import data.SpotifyPlayer
import io.github.jan.supabase.gotrue.SessionSource
import io.github.jan.supabase.gotrue.SessionStatus
import io.github.jan.supabase.gotrue.auth
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.pause
import loca.composeapp.generated.resources.play
import org.jetbrains.compose.resources.vectorResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.AccountPage
import ui.LandingPage
import ui.LocaTheme
import ui.LoginScreen
import ui.SigningInState
import ui.create.ArtistOnboarding
import ui.create.PageEditor
import ui.defaultColors
import util.Location

@OptIn(ExperimentalVoyagerApi::class)
@Composable
@Preview
fun App(
    deepLink: String? = null,
    ads: Ads? = null,
    spotifyPlayer: SpotifyPlayer? = null,
    locationServices: LocationServices? = null,
    router: LocaRouter? = null,
    navController: NavHostController = rememberNavController(),
    authProvider: AuthProvider = AuthProvider(),
) {

    val audioPlayer = LocalAudioPlayer.current
    LocalSpotifyPlayer.current.apply { this.value = spotifyPlayer }
    LocalAds.current.apply { this.value = ads }

    val location = LocalLocation.current
    val places = LocalPlaces.current

    val playerState = LocalSpotifyPlayerState.current

    val supabase = LocalSupabase.current

    var isLoggedIn by remember { mutableStateOf<Boolean?>(null) }
    var selectedArtist by remember { mutableStateOf<Artist?>(null) }

    val coroutineScope = rememberCoroutineScope(
        getContext = { Dispatchers.Unconfined }
    )

    LaunchedEffect(true) {

        val currentLocation = locationServices?.requestCurrentLocation()
            if (currentLocation != null) {
                location.value = Location("Near me", currentLocation)
            } else {
                locationServices?.requestLocationPermission { result ->

                }
            }

        spotifyPlayer?.connect()
        withContext(Dispatchers.Unconfined) {
            spotifyPlayer?.playerState()?.collect {
                withContext(Dispatchers.Main) {
                    playerState.value = it
                }
            }
        }

        withContext(Dispatchers.Unconfined) {
//            auth.authStateChanged.collectLatest { user ->
//                if (isLoggedIn != null && user == null) {
//                    navController.navigate("/")
//                    router?.push("/")
//                }
//                isLoggedIn = user != null
//            }

            supabase.auth.currentSessionOrNull()?.user?.email
            supabase.auth.sessionStatus.collect {
                when(it) {
                    is SessionStatus.Authenticated -> {
                        println("Received new authenticated session.")
                        isLoggedIn = true
                        when(it.source) { //Check the source of the session
                            SessionSource.External -> {}
                            is SessionSource.Refresh -> {}
                            is SessionSource.SignIn -> {
                                navController.navigate("/me")
                                router?.push("/me")
                            }
                            is SessionSource.SignUp -> {
                                navController.navigate("/me")
                                router?.push("/me")
                            }
                            SessionSource.Storage -> {}
                            SessionSource.Unknown -> {}
                            is SessionSource.UserChanged -> {}
                            is SessionSource.UserIdentitiesChanged -> {}
                            SessionSource.AnonymousSignIn -> {}
                        }
                    }
                    SessionStatus.LoadingFromStorage -> println("Loading from storage")
                    SessionStatus.NetworkError -> println("Network error")
                    is SessionStatus.NotAuthenticated -> {
                        isLoggedIn = false
                        if(it.isSignOut) {
                            navController.navigate("/")
                            router?.push("/")
                            println("User signed out")
                        } else {
                            println("User not signed in")
                        }
                    }
                }
            }

        }
    }

    val darkMode = isSystemInDarkTheme()
    var colors by remember { mutableStateOf(defaultColors(darkMode)) }

    val listColors = listOf(
        MaterialTheme.colorScheme.background,
        MaterialTheme.colorScheme.primary,
        MaterialTheme.colorScheme.secondary,
    )
    val tileSize = with(LocalDensity.current) {
        50.dp.toPx()
    }

    LocaTheme(
        colors = colors
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(MaterialTheme.colorScheme.tertiaryContainer)
                .background(
                    Brush.sweepGradient(listOf(Color.Cyan, Color.Magenta))
                )
//                .background(
//                    Brush.linearGradient(
//                        listColors,
//                        tileMode = TileMode.Repeated
//                    )
//                )
        ) {
            Scaffold(
                modifier = Modifier,
                topBar = {
//                        (navigator.lastItem as? LocaScreen)?.takeIf { it.hasTopBar }?.let { screen ->
//                            Box(
//                                modifier = Modifier
//                                    .background(MaterialTheme.colorScheme.surfaceContainer)
//                                    .statusBarsPadding()
//                                    .fillMaxWidth()
//                                    .padding(vertical = 8.dp)
//                            ) {
//                                AnimatedVisibility(
//                                    true,
//                                    modifier = Modifier
//                                        .height(48.dp)
//                                        .fillMaxWidth()
//                                        .align(Alignment.Center),
//                                    enter = fadeIn(),
//                                    exit = fadeOut()
//                                ) {
//                                    screen.TopBar()
//                                }
//                            }
//                        }
                }
            ) {
                AnimatedVisibility(
                    visible = isLoggedIn != null,
                    enter = fadeIn(),
                    exit = fadeOut(),
                ) {

                    Box(
                        modifier = Modifier.fillMaxSize()
                    ) {

                        var username = ""
                        val link = deepLink?.let {
                            if (it.startsWith("/@")) {
                                username = deepLink.removePrefix("/@")
                                "/@"
                            } else {
                                it
                            }
                        } ?: "/"

                        val loggedOutLinks = listOf("/", "/login", "/register")
                        val loggedInLinks = listOf("/me", "/create-artist")

                        val finalLink = if (isLoggedIn == true && loggedOutLinks.contains(link)) {
                            router?.push("/me")
                            "/me"
                        } else if (isLoggedIn == false && loggedInLinks.contains(link)) {
                            router?.push("/")
                            "/"
                        } else {
                            link
                        }

                        NavHost(
                            navController = navController,
                            startDestination = finalLink,
                            enterTransition = {
                                slideInVertically { it }
                            },
                            exitTransition = {
                                slideOutVertically { -it }
                            },
                            popEnterTransition = {
                                slideInVertically { -it }
                            },
                            popExitTransition = {
                                slideOutVertically { it }
                            },
                            modifier = Modifier
                                .fillMaxSize()
//            .verticalScroll(rememberScrollState())
//            .padding(innerPadding)
                        ) {

                            composable("/") {
                                NarrowBox {
                                    LandingPage(
                                        onRegisterClick = {
                                            navController.navigate("/register")
                                            router?.push("register")
                                        },
                                        onLoginClick = {
                                            navController.navigate("/login")
                                            router?.push("/login")
                                        }
                                    )
                                }
                            }

                            composable("/login") {
                                NarrowBox {
                                    LoginScreen(
                                        authProvider = authProvider,
                                        signingInState = SigningInState.LOGIN,
                                        onLoggedIn = {
                                            navController.navigate("/me")
                                        }
                                    )
                                }
                            }

                            composable("/register") {
                                NarrowBox {
                                    LoginScreen(
                                        authProvider = authProvider,
                                        signingInState = SigningInState.REGISTER,
                                        onLoggedIn = {
                                            navController.navigate("/me")
                                            router?.push("/me")
                                        }
                                    )
                                }
                            }

                            composable("/@") {
                                NarrowBox {
                                    FetchArtistPage(
                                        username = username,
                                        onArtistFetch = { fetchedArtist ->
                                            fetchedArtist?.let {
                                                val name =
                                                    fetchedArtist.name ?: "@${fetchedArtist.handle}"
                                                router?.changeTitle("$name | Loca")
                                            }
                                        },
                                        onColorsChanged = { colorScheme ->
                                            colors = colorScheme
                                        }
                                    )
                                }
                            }

                            composable("/me") {
                                NarrowBox {
                                    AccountPage(
                                        onAddArtistClick = {
                                            navController.navigate("/create")
                                            router?.push("/create")
                                        },
                                        onEditArtistClick = {
                                            selectedArtist = it
                                            navController.navigate("/editor")
                                            router?.push("/editor")
                                        },
                                        onLogoutClick = {
                                            coroutineScope.launch {
                                                supabase.auth.signOut()
                                            }
                                        }
                                    )
                                }
                            }

                            composable("/create") {

                                if (selectedArtist?.osmId != null) {
                                    navController.navigate("/editor")
                                    router?.push("/editor")
                                }

                                val artist = remember { selectedArtist }

                                NarrowBox {
                                    ArtistOnboarding(
                                        selectedArtist = artist,
                                        onClose = {
                                            navController.popBackStack()
                                            router?.pop()
                                            selectedArtist = null
                                        }
                                    ) { artist ->
                                        selectedArtist = artist
                                    }
                                }
                            }

                            composable("/editor") {
                                if (selectedArtist == null) {
                                    navController.navigate("/me")
                                    router?.push("/me")
                                } else if (selectedArtist?.spotify == null) {
                                    navController.navigate("/create")
                                    router?.push("/create")
                                }

                                var showDialog by remember { mutableStateOf(false) }
                                AnimatedVisibility(selectedArtist != null) {
                                    selectedArtist?.let {
                                        WideBox {
                                            PageEditor(
                                                it,
                                                showDialog = showDialog,
                                                onColorChange = {
                                                    colors = it
                                                }
                                            ) {
                                                navController.popBackStack()
                                                router?.pop()
                                                selectedArtist = null
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        Row(modifier = Modifier.align(Alignment.BottomCenter)) {
                            AnimatedVisibility(
                                visible = playerState.value != null,
                                enter = fadeIn(),
                                exit = fadeOut()
                            ) {
                                Row(
                                    modifier = Modifier
                                        .navigationBarsPadding()
                                        .padding(bottom = 12.dp, start = 12.dp, end = 12.dp)
                                        .clip(RoundedCornerShape(24.dp))
                                        .fillMaxWidth()
                                        .height(72.dp)
                                        .background(MaterialTheme.colorScheme.surfaceContainer.copy(alpha = 0.95f))
                                        .padding(12.dp),
                                ) {
                                    val isPaused = playerState.value?.isPaused

                                    Spacer(Modifier.width(12.dp))

                                    Column(modifier = Modifier.align(Alignment.CenterVertically)) {
                                        Text(
                                            modifier = Modifier,
                                            text = playerState.value?.track?.name ?: "",
                                            fontWeight = FontWeight.SemiBold,
                                            fontSize = 14.sp,
                                            lineHeight = 18.sp
                                        )
//                                        Text(
//                                            modifier = Modifier,
//                                            text = playerState.value?.track?.artists?. ?: "",
//                                            fontSize = 14.sp,
//                                            lineHeight = 18.sp
//                                        )
                                    }

                                    Spacer(Modifier.weight(1f, true))

                                    Box(
                                        modifier = Modifier
                                            .size(52.dp)
                                            .clip(CircleShape)
                                            .clickable {
                                                if (isPaused ?: false) {
                                                    spotifyPlayer?.resume()
                                                } else {
                                                    spotifyPlayer?.pause()
                                                }
                                            }
                                            .background(MaterialTheme.colorScheme.primary),
                                    ) {
                                        if (isPaused ?: false) {
                                            Icon(
                                                modifier = Modifier.size(24.dp)
                                                    .align(Alignment.Center),
                                                imageVector = vectorResource(Res.drawable.play),
                                                contentDescription = "Play",
                                                tint = MaterialTheme.colorScheme.onPrimary
                                            )
                                        } else {
                                            Icon(
                                                modifier = Modifier.size(24.dp)
                                                    .align(Alignment.Center),
                                                imageVector = vectorResource(Res.drawable.pause),
                                                contentDescription = "Pause",
                                                tint = MaterialTheme.colorScheme.onPrimary
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}

@Composable
fun NarrowBox(
    content: @Composable () -> Unit
) {
    val portrait = ScreenInfo().isPortrait()
    val verticalPadding = if (portrait) 0.dp else 24.dp
    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Box(
            modifier = if (portrait) Modifier else Modifier
                .requiredWidthIn(0.dp, 512.dp)
                .padding(vertical = verticalPadding)
                .align(Alignment.Center)
        ) {
            content()
        }
    }
}

@Composable
fun WideBox(
    content: @Composable () -> Unit
) {
    val portrait = ScreenInfo().isPortrait()
    val verticalPadding = if (portrait) 0.dp else 0.dp
    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Box(
            modifier = if (portrait) Modifier else Modifier
                .requiredWidthIn(0.dp, 1024.dp)
                .padding(vertical = verticalPadding)
                .align(Alignment.Center)
        ) {
            content()
        }
    }
}
