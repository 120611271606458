package ui.create

import Artist
import ArtistPage
import Link
import ScreenInfo
import Theme
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ColorScheme
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.toMutableStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.LocalPlatformContext
import com.github.ajalt.colormath.extensions.android.composecolor.toComposeColor
import com.github.ajalt.colormath.model.RGB
import com.materialkolor.PaletteStyle
import com.materialkolor.dynamicColorScheme
import com.materialkolor.ktx.hasEnoughContrast
import data.LocalSupabase
import data.artists
import io.github.jan.supabase.postgrest.from
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.arrow_left
import loca.composeapp.generated.resources.copy
import loca.composeapp.generated.resources.edit
import loca.composeapp.generated.resources.eye
import loca.composeapp.generated.resources.location
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaOutlinedButton
import ui.component.LocaTextField
import ui.defaultColors

@Composable
fun CreateArtist(onCreatedArtist: (handle: String) -> Unit) {

    var editedArtist by remember { mutableStateOf<Artist?>(null) }
    val navController = rememberNavController()

    val initialPage = when {
        editedArtist?.spotify != null -> "/page-editor"
        editedArtist?.handle != null -> "/find-spotify"
        else -> "/"
    }

    Box (
        modifier = Modifier.fillMaxSize(),
    ) {
        NavHost(
            modifier = Modifier,
            navController = navController,
            startDestination = initialPage,
            enterTransition = {
                slideInVertically { it }
            },
            exitTransition = {
                slideOutVertically { -it }
            },
            popEnterTransition = {
                slideInVertically { -it }
            },
            popExitTransition = {
                slideOutVertically { it }
            }
        ) {

            composable("/") {

            }

            composable("/find-spotify") {
//                FindBand { spotifyArtist ->
//                    editedArtist = artist?.copy(
//                        name = spotifyArtist.name,
//                        spotify = spotifyArtist.id,
//                        picture = spotifyArtist.images.firstOrNull()?.url
//                    )
//                    navController.navigate("/confirm-spotify")
//                }
            }

            composable("/confirm-spotify") {
//                ConfirmBand(
//                    artist!!,
//                    onNo = { navController.popBackStack() },
//                    onYes = { }
//                )
            }

            composable("/page-editor") {
            }
        }
    }
}

fun Theme.toColorScheme() =
    seedColor?.let { RGB(it).toComposeColor() }?.let { color ->
        val isDark = color.hasEnoughContrast(Color.White)
        val colorScheme = dynamicColorScheme(
            seedColor = color,
            isDark = isDark,
            isAmoled = false,
            style = PaletteStyle.Vibrant,
            isExtendedFidelity = true,
        )

        when (style) {
            "bold" -> colorScheme.let {
                it.copy(
                    background = color,
                    onBackground = it.onPrimaryContainer,
                )
            }
            else -> colorScheme
        }
    }


@OptIn(ExperimentalCoilApi::class)
@Composable
fun PageEditor(
    artist: Artist,
    showDialog: Boolean = false,
    onColorChange: (ColorScheme) -> Unit,
    onSave: (Artist) -> Unit,
) {

    var editedArtist by remember { mutableStateOf(artist) }

    val isPortrait = ScreenInfo().isPortrait()
    val navController = rememberNavController()
    val currentBackStackEntry by navController.currentBackStackEntryAsState()

    val supabase = LocalSupabase.current
    val coroutineScope = rememberCoroutineScope { Dispatchers.Unconfined }

    val context = LocalPlatformContext.current
    val initialColor = MaterialTheme.colorScheme.primary
    var seedColor by remember { mutableStateOf(initialColor) }
    val isDarkMode = isSystemInDarkTheme()
    var colorScheme by remember {
        val theme = artist.theme?.toColorScheme() ?: defaultColors(isDarkMode)
        mutableStateOf(theme)
    }
//    val colorScheme = rememberDynamicColorScheme(
//        seedColor = seedColor,
//        isDark = true,
//        style = PaletteStyle.Fidelity,
//    )

//    LaunchedEffect(true) {
//        val imageRequest = ImageRequest.Builder(context)
//            .data(artist.picture!!)
//            .build()
//        ImageLoader.Builder(context)
//            .build()
//            .execute(imageRequest)
//            .image?.let { bitmap ->
////                seedColor = bitmap.toBitmap().let {
////                    val intArray = it.readPixels()?.map { it.toInt() }?.toIntArray()!!
////                    val quantized = QuantizerCelebi.quantize(intArray, 128)
////                    Score.score(
////                        colorsToPopulation = quantized,
////                        desired = 4,
////                        fallbackColorArgb = Color.Blue.toArgb(),
////                        filter = false,
////                    ).map { Color(it) }
////                }.first()
//////            }
//                val palette = Palette.generate(arrayListOf<Int>().apply {
//                    bitmap.toBitmap().readPixels()?.forEach {
//                        this.add(it.toInt())
//                    }
//                }.toIntArray())
//                colorScheme = colorScheme.copy(
//                    primary = palette.primarySwatch!!.color.toComposeColor(),
//                    surface = palette.primarySwatch!!.secondaryOnColor.toComposeColor()
//                )
//            }
//    }

    LaunchedEffect(colorScheme) {
//        val colors = locaDarkcolorScheme.copy(
//            primary = colorScheme.primary,
//            primaryVariant = colorScheme.surfaceContainer,
//            secondary = colorScheme.secondary,
//            secondaryVariant = colorScheme.surfaceContainer,
//            background = colorScheme.background,
//            surface = colorScheme.surfaceContainer,
//            error = colorScheme.error,
//            onPrimary = colorScheme.onPrimary,
//            onSecondary = colorScheme.onSecondary,
//            onBackground = colorScheme.onBackground,
//            onSurface = colorScheme.onSurface,
//            onError = colorScheme.onError,
//            isLight = false
//        )
        onColorChange(colorScheme)
    }

    DisposableEffect(true) {
        onDispose { onColorChange(defaultColors(isDarkMode)) }
    }

    if (showDialog) {
//        AlertDialog(
//            onDismissRequest = {
//
//            },
//            buttons = {
//
//            },
//            title = {
//                Text("Unsaved Changes")
//            }
//        )
    }

    Row(
        modifier = Modifier.fillMaxSize()
    ) {

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .weight(1f, true)
        ) {

            Row(
                modifier = if (isPortrait) Modifier
                    .fillMaxWidth()
                    .background(MaterialTheme.colorScheme.surfaceContainer.copy(0.8f))
                    .padding(vertical = 12.dp)
                    .padding(end = 24.dp)
                else Modifier
                    .fillMaxWidth()
                    .padding(top = 24.dp)
                    .clip(CircleShape)
                    .background(MaterialTheme.colorScheme.surfaceContainer.copy(0.8f))
                    .padding(vertical = 12.dp)
                    .padding(end = 24.dp)
            ) {
                when (currentBackStackEntry?.destination?.route) {
                    "/preview" -> {
                        IconButton(
                            modifier = Modifier.padding(start = 8.dp, end = 4.dp),
                            onClick = {
                                if (navController.previousBackStackEntry == null) {
                                    onSave(artist)
                                } else {
                                    navController.popBackStack()
                                }
                            },
                        ) {
                            Icon(
                                imageVector = vectorResource(Res.drawable.arrow_left),
                                contentDescription = "Back"
                            )
                        }

                        Text(
                            modifier = Modifier.align(Alignment.CenterVertically),
                            text = "Preview",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 18.sp
                        )

                        Spacer(Modifier.weight(1f, true))

                        Button(
                            onClick = {
                                navController.popBackStack()
                            },
                            shape = CircleShape
                        ) {
                            Icon(
                                imageVector = vectorResource(Res.drawable.edit),
                                contentDescription = "Edit"
                            )

                            Spacer(Modifier.width(8.dp))

                            Text("Edit")
                        }
                    }

                    else -> {
                        IconButton(
                            modifier = Modifier.padding(start = 8.dp, end = 4.dp),
                            onClick = {
                                if (navController.previousBackStackEntry == null) {
                                    onSave(artist)
                                } else {
                                    navController.popBackStack()
                                }
                            },
                        ) {
                            Icon(
                                imageVector = vectorResource(Res.drawable.arrow_left),
                                contentDescription = "Back"
                            )
                        }

                        Text(
                            modifier = Modifier.align(Alignment.CenterVertically),
                            text = "Editor",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 18.sp
                        )

                        Spacer(Modifier.weight(1f, true))

                        Button(
                            modifier = Modifier,
                            onClick = {
                                if (isPortrait) {
                                    navController.navigate("/preview")
                                } else {
                                    coroutineScope.launch {
                                        supabase.artists.update(editedArtist)
                                        onSave(editedArtist)
                                    }
                                }
                            },
                            shape = CircleShape,
                        ) {
                            if (isPortrait) {
                                Icon(
                                    imageVector = vectorResource(Res.drawable.eye),
                                    contentDescription = "Preview"
                                )

                                Spacer(Modifier.width(8.dp))

                                Text("Preview")
                            } else {
//                                Icon(
//                                    imageVector = vectorResource(Res.drawable.),
//                                    contentDescription = "Preview"
//                                )

//                                Spacer(Modifier.width(8.dp))

                                Text("Save")
                            }
                        }
                    }
                }
            }

            NavHost(
                modifier = Modifier.fillMaxSize(),
                navController = navController,
                startDestination = "/",
                enterTransition = {
                    slideInVertically { it }
                },
                exitTransition = {
                    slideOutVertically { -it }
                },
                popEnterTransition = {
                    slideInVertically { -it }
                },
                popExitTransition = {
                    slideOutVertically { it }
                },
            ) {
                composable("/") {
                    Column(Modifier.fillMaxWidth()) {

                        LazyColumn(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(
                                    start = 24.dp,
                                    end = 24.dp
                                )
                        ) {
                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.name ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(name = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Name"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.caption ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(caption = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Description"
                                )
                            }

                            item {
                                LocaOutlinedButton(
                                    onClick = { navController.navigate("/location") }
                                ) {
                                    Row {
                                        Icon(
                                            imageVector = vectorResource(Res.drawable.location),
                                            contentDescription = "Location"
                                        )

                                        Spacer(Modifier.width(12.dp))

                                        Text(
                                            modifier = Modifier.align(Alignment.CenterVertically),
                                            text = editedArtist.place?.name ?: "Select City"
                                        )
                                    }
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaOutlinedButton(
                                    onClick = { navController.navigate("/theme") }
                                ) {
                                    Row {
                                        Text(
                                            modifier = Modifier.align(Alignment.CenterVertically),
                                            text = "Theme"
                                        )
                                    }
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaOutlinedButton(
                                    onClick = { navController.navigate("/sounds-like") }
                                ) {
                                    Text("Sounds Like")
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaOutlinedButton(
                                    onClick = { navController.navigate("/genres") }
                                ) {
                                    Text("Genres")
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaOutlinedButton(
                                    onClick = { navController.navigate("/links") }
                                ) {
                                    Text("Links")
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.facebook ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(facebook = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Facebook Link"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.instagram ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(instagram = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Instagram Link"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.tiktok ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(tiktok = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "TikTok Link"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.email ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(email = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Contact Email"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.appleMusic ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(appleMusic = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Apple Music Link"
                                )
                            }

                            item {
                                LocaTextField(
                                    value = editedArtist.bio ?: "",
                                    onValueChange = {
                                        editedArtist =
                                            editedArtist.copy(bio = it.takeIf { it.isNotEmpty() })
                                    },
                                    label = "Bio",
                                    singleLine = false
                                )
                            }

                            item {
                                Button(
                                    modifier = Modifier.align(Alignment.CenterHorizontally)
                                        .fillMaxWidth(),
                                    onClick = {
                                        coroutineScope.launch {
                                            supabase.artists.update(editedArtist)
//                                            firestore.updateArtist(editedArtist)
                                            onSave(editedArtist)
                                        }
                                    },
                                    shape = CircleShape
                                ) {
                                    Text(
                                        modifier = Modifier.padding(12.dp),
                                        text = "Save Changes"
                                    )
                                }

                                Spacer(Modifier.height(12.dp))

                                TextButton(
                                    modifier = Modifier
                                        .align(Alignment.CenterHorizontally)
                                        .fillMaxWidth(),
                                    onClick = {
                                        coroutineScope.launch {
                                            supabase.from("artists").delete {
                                                filter { eq("id", editedArtist.id ?: 0) }
                                            }
                                            onSave(editedArtist)
                                        }
                                    },
                                    shape = CircleShape,
                                    colors = ButtonDefaults.textButtonColors(contentColor = MaterialTheme.colorScheme.error)
                                ) {
                                    Text(
                                        modifier = Modifier.padding(12.dp),
                                        text = "Delete"
                                    )
                                }
                            }

                            item {
                                Spacer(Modifier.height(24.dp))
                            }
                        }
                    }
                }

                composable("/preview") {
                    Column(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        ArtistPage(editedArtist, onColorsChanged = {})
                    }
                }

                composable("/location") {
                    Box(Modifier.padding(horizontal = 24.dp)) {
                        LocationSearch { place ->
                            coroutineScope.launch {
                                supabase.artists.addOrGetPlace(place)

                                editedArtist = editedArtist.copy(
                                    place = place,
                                    osmId = place.osmId,
                                    point = place.point
                                )

                                navController.popBackStack()
                            }
                        }
                    }
                }

                composable("/sounds-like") {
                    SoundLike(selected = editedArtist.soundsLike ?: listOf()) { soundsLike ->
                        editedArtist = editedArtist.copy(
                            soundsLike = soundsLike
                        )
                        navController.popBackStack()
                    }
                }

                composable("/genres") {
                    val selectedGenres = remember {
                        (editedArtist.genres?.filter { it != "null" } ?: listOf())
                            .toMutableStateList()
                    }

                    editedArtist = editedArtist.copy(
                        genres = selectedGenres
                    )

                    PickGenres(
                        selectedGenres,
                        editedArtist.soundsLike ?: listOf()
                    ) {
                        navController.popBackStack()
                    }
                }

                composable("/links") {
                    LinksEditor(
                        editedArtist.links ?: listOf(),
                        {
                            navController.navigate("/new-link")
                        },
                        {

                        },
                        {
                            navController.navigate("/new-link")
                        }
                    )
                }

                composable("/new-link") {
                    NewLink { url, title, picture ->
                        val link = Link(
                            artistId = artist.id ?: 0,
                            title = title,
                            url = url,
                            picture = picture
                        )
                        editedArtist = editedArtist.copy(
                            links = editedArtist.links?.toMutableList()?.apply {
                                add(link)
                            }
                        )
                        coroutineScope.launch {
                            supabase.artists.addLink(link)
                        }
                        navController.popBackStack()
                    }
                }

                composable("/theme") {
                    ThemePicker(
                        editedArtist,
                        colorScheme,
                        onColorsChange = {
                            onColorChange(it)

                        },
                        onConfirm = {
                            coroutineScope.launch {
                                editedArtist = editedArtist.copy(
                                    theme = it
                                )
                                supabase.artists.update(editedArtist)
//                                firestore.updateArtist(editedArtist)
                                navController.popBackStack()
                            }
                        }
                    )
                }

            }
        }

        if (!isPortrait) {
            val clipboardManager = LocalClipboardManager.current
            val link = "https://loca.fm/@${artist.handle}"
            var copied by remember { mutableStateOf(false) }
            val linkAlpha = animateFloatAsState(if (copied) 0f else 1f)
            Column(
                Modifier
                    .fillMaxHeight()
                    .weight(1f, true)
                    .padding(24.dp)
                    .clip(RoundedCornerShape(48.dp))
                    .background(MaterialTheme.colorScheme.surfaceContainer)
                    .padding(24.dp)
            ) {

                Text(
                    modifier = Modifier.align(Alignment.CenterHorizontally),
                    text = "Preview",
                    fontWeight = FontWeight.SemiBold,
                    fontSize = 18.sp,
                    color = MaterialTheme.colorScheme.onSurface
                )

                Spacer(Modifier.height(12.dp))

                OutlinedButton(
                    modifier = Modifier
                        .align(Alignment.CenterHorizontally),
                    onClick = {
                        clipboardManager.setText(AnnotatedString(link))
                        coroutineScope.launch {
                            copied = true
                            delay(1000)
                            copied = false
                        }
                    },
                    shape = CircleShape
                ) {
                    Icon(
                        modifier = Modifier.padding(4.dp),
                        imageVector = vectorResource(Res.drawable.copy),
                        contentDescription = "Copy"
                    )
                    Spacer(Modifier.width(4.dp))
                    Box {
                        Text(
                            modifier = Modifier.alpha(linkAlpha.value).align(Alignment.Center),
                            text = link
                        )
                        Text(
                            modifier = Modifier.alpha(1f - linkAlpha.value).align(Alignment.Center),
                            text = "Copied!"
                        )
                    }
                }

//                Text(
//                    modifier = Modifier
//                        .align(Alignment.CenterHorizontally),
//                    text = "Copy for your Instagram bio \uD83D\uDE4F"
//                )

                Spacer(Modifier.height(12.dp))

                Box(
                    modifier = Modifier
                        .fillMaxHeight()
                        .border(
                            2.dp,
                            MaterialTheme.colorScheme.onBackground.copy(alpha = 0.1f),
                            RoundedCornerShape(24.dp)
                        )
                        .clip(RoundedCornerShape(24.dp))
                        .background(MaterialTheme.colorScheme.background)
                ) {
                    ArtistPage(editedArtist, onColorsChanged = {})
                }
            }
        }
    }
}

@Composable
fun SelectHandle(
    onSelected: (Artist) -> Unit
) {

    var handle by remember { mutableStateOf("") }
    var loading by remember { mutableStateOf(false) }
    var canCreateHandle by remember { mutableStateOf(false) }
    var error by remember { mutableStateOf<String?>(null) }
    var handleCheckJob by remember { mutableStateOf<Job?>(null) }

    val supabase = LocalSupabase.current

    val coroutineScope = rememberCoroutineScope { Dispatchers.Unconfined }

        LazyColumn (
            modifier = Modifier.fillMaxSize()
        ) {

            item {
                Spacer(Modifier.height(48.dp))

                Column(
                    Modifier.fillMaxWidth()
                ) {

                    Text(
                        modifier = Modifier
                            .align(Alignment.CenterHorizontally),
                        text = "Choose your artist username",
                        fontWeight = FontWeight.SemiBold,
                        fontSize = 20.sp
                    )

                    Spacer(Modifier.height(12.dp))

                    Text(
                        modifier = Modifier.align(Alignment.CenterHorizontally),
                        text = "This is your identifier on Loca and your link",
                        fontWeight = FontWeight.Normal,
                        fontSize = 14.sp,
                        color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.75f),
                        textAlign = TextAlign.Center
                    )

                    Spacer(Modifier.height(24.dp))

                    Row(
                        modifier = Modifier
                            .align(Alignment.CenterHorizontally),
                    ) {
                        LocaTextField(
                            modifier = Modifier.fillMaxWidth(),
                            value = handle,
                            onValueChange = {
                                handle = it
                                error = null
                                handleCheckJob?.cancel()
                                handleCheckJob = coroutineScope.launch {
                                    val toCheck = handle
                                    if (handle.length < 4) {
                                        error = "Must be at least 4 characters"
                                        loading = false
                                    } else if (handle.length > 24) {
                                        error = "Must be less than 25 characters"
                                        loading = false
                                    } else {
                                        loading = true

                                        delay(1500)
                                        if (toCheck == handle) {
                                            val handleExists = supabase.from("artists").select {
                                                filter { eq("handle", handle) }
                                            }.countOrNull() ?: 0
                                            if (handleExists > 0) {
                                                error = "Username already exists"
                                            }
                                            canCreateHandle = handleExists == 0L
                                        }
                                        loading = false
                                    }
                                }
                            },
                            leadingIcon = {
                                Text(
                                    modifier = Modifier
                                        .align(Alignment.CenterVertically)
                                        .padding(start = 12.dp),
                                    text = "@",
                                    fontSize = 20.sp
                                )
                            },
                            label = "Username",
                            isError = error != null,
                            errorLabel = error,
                            keyboardOptions = KeyboardOptions.Default.copy(
                                autoCorrect = false
                            ),
                            trailingIcon = {
                                AnimatedVisibility(loading) {
                                    CircularProgressIndicator(
                                        modifier = Modifier.size(24.dp)
                                    )
                                }
                            }
                        )
                    }

                    Spacer(Modifier.height(8.dp))

                    Button(
                        modifier = Modifier
                            .fillMaxWidth(),
                        onClick = {
                            coroutineScope.launch {
                                loading = true
                                supabase.artists.create(handle)?.let {
                                    onSelected(it)
                                }
//                                    val artist = firestore.createArtist(
//                                        handle,
//                                        email
//                                    )
                                loading = false
                            }
                        },
                        enabled = !loading && canCreateHandle && error == null,
                        shape = CircleShape,
                        contentPadding = PaddingValues(24.dp)
                    ) {
                        Text("Next")
                    }

                    Spacer(Modifier.height(24.dp))

                    AnimatedVisibility(
                        handle.isNotEmpty(),
                        enter = fadeIn(),
                        exit = fadeOut(),
                        modifier = Modifier.align(Alignment.CenterHorizontally),
                    ) {
                        Column {
                            Row(Modifier.align(Alignment.CenterHorizontally)) {
                                Text(
                                    text = "https://loca.fm/",
                                    color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.75f)
                                )
                                Text(
                                    text = "@${handle}",
                                    fontWeight = FontWeight.SemiBold
                                )
                            }
                            Text(
                                modifier = Modifier.align(Alignment.CenterHorizontally),
                                text = "We may reclaim your username if you impersonate someone",
                                color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f),
                                fontSize = 12.sp,
                                textAlign = TextAlign.Center
                            )
                        }
                    }
                }
            }
    }
}





