package model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object PointSerializer : KSerializer<Point> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Point", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Point) {
        encoder.encodeString("POINT(${value.latitude} ${value.longitude})")
    }

    override fun deserialize(decoder: Decoder): Point {
        return decoder
            .decodeString()
            .removePrefix("POINT(")
            .removeSuffix(")")
            .split(" ")
            .map { it.toDoubleOrNull() }
            .let {
                Point(
                    latitude = it.getOrNull(0) ?: 0.0,
                    longitude = it.getOrNull(1) ?: 0.0
                )
            }
    }
}

@Serializable(with = PointSerializer::class)
data class Point(
    val latitude: Double,
    val longitude: Double,
)