package util

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import model.Point
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.max
import kotlin.math.min
import kotlin.math.sin
import kotlin.math.sqrt

data class GeoBoundingBox(
    val swCorner: Point,
    val neCorner: Point
)

@Serializable
data class Location(
    val name: String,
    val point: Point,
    @SerialName("place_id") val placeId: String? = null,
    val country: String? = null,
    val region: String? = null,
)

@Serializable
data class Place(
    val id: Int? = null,
    val name: String,
    @SerialName("osm_id") val osmId: String,
    @SerialName("osm_key") val osmKey: String,
    @SerialName("osm_value") val osmValue: String,
    val point: Point,
    val country: String? = null,
    val state: String? = null,
)

 /**
 * Calculates the SW and NE corners of a bounding box around a center point for a given radius;
 *
 * @param {Object} center The center given as .latitude and .longitude
 * @param {number} radius The radius of the box (in kilometers)
 * @return {Object} The SW and NE corners given as .swCorner and .neCorner
 */
fun Point.boundingBox(radius: Double): GeoBoundingBox {
    val KM_PER_DEGREE_LATITUDE = 110.574
    val latDegrees = radius / KM_PER_DEGREE_LATITUDE
    val latitudeNorth = min(90.0, this.latitude + latDegrees)
    val latitudeSouth = max(-90.0, this.latitude - latDegrees)
    // calculate longitude based on current latitude
    val longDegsNorth = metersToLongitudeDegrees(radius, latitudeNorth)
    val longDegsSouth = metersToLongitudeDegrees(radius, latitudeSouth)
    val longDegs = max(longDegsNorth, longDegsSouth)
    return GeoBoundingBox(
        swCorner = Point(
            latitude = latitudeSouth,
            longitude = wrapLongitude(this.longitude - longDegs)
        ),
        neCorner = Point(
            latitude = latitudeNorth,
            longitude = wrapLongitude(this.longitude + longDegs)
        )
    )
}

/**
 * Calculates the number of degrees a given distance is at a given latitude.
 *
 * @param {number} distance The distance to convert.
 * @param {number} latitude The latitude at which to calculate.
 * @return {number} The number of degrees the distance corresponds to.
 */
fun metersToLongitudeDegrees(distance: Double, latitude: Double): Double {
    val EARTH_EQ_RADIUS = 6378137.0
    // this is a super, fancy magic number that the GeoFire lib can explain (maybe)
    val E2 = 0.00669447819799
    val EPSILON = 1e-12
    val radians = degreesToRadians(latitude)
    val num = cos(radians) * EARTH_EQ_RADIUS * PI / 180
    val denom = 1 / sqrt(1 - E2 * sin(radians) * sin(radians))
    val deltaDeg = num * denom
    if (deltaDeg < EPSILON) {
        return if (distance > 0.0) 360.0 else 0.0
    }
    // else
    return min(360.0, distance / deltaDeg);
}

fun degreesToRadians(degrees: Double): Double {
    return (degrees * PI) / 180
}

/**
 * Wraps the longitude to [-180,180].
 *
 * @param {number} longitude The longitude to wrap.
 * @return {number} longitude The resulting longitude.
 */
fun wrapLongitude(longitude: Double): Double {
    if (longitude <= 180 && longitude >= -180) {
        return longitude;
    }
    val adjusted = longitude + 180;
    if (adjusted > 0) {
        return (adjusted % 360) - 180;
    }
    // else
    return 180 - (-adjusted % 360);
}